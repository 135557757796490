import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';

import { User } from './classes/user';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from './backend.service';
import { url } from 'inspector';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apollo: Apollo,
    private router: Router,
    private backendService: BackendService
  ) {}

  private userInfoSource: BehaviorSubject<User | null> =  new BehaviorSubject(null);
  userInfo: Observable<User> = this.userInfoSource.asObservable();

  authenticateUser(idToken: string, originUrl?: string): void {
    this.apollo
    .watchQuery<Response>({
      query: gql`
      {
        authenticateUser(idToken: "${idToken}") {
          bearerToken
          expiresAt
          userId
          email
          firstName
          lastName
          profilePicture
          status
          previews
        }
      }
      `
    })
    .valueChanges.subscribe(response => {
      let authenticatedUser = {...response.data['authenticateUser']};
      authenticatedUser.previews = authenticatedUser.previews.join(',');
      this.setSession(authenticatedUser);
      if(originUrl) {
        const path = new URL(originUrl, 'http://domain').pathname;
        const queryParams = new URL(originUrl, 'http://domain').searchParams;
        let queryParamsObject = {};
        for (const [key, value] of queryParams.entries()) {
          queryParamsObject[key] = value;
        }
        this.router.navigate([path], { queryParams: queryParamsObject });
      } else if((this.router.url.split('/')[1] && this.router.url.split('/')[1].startsWith('auth')) || (this.router.url.split('/')[2] && this.router.url.split('/')[2].startsWith('auth'))) {
        this.router.navigate(['/']);
      } else if((this.router.url.split('/')[1] && this.router.url.split('/')[1]).startsWith('search') || (this.router.url.split('/')[2] && this.router.url.split('/')[2].startsWith('search'))) {
        this.search();
      }
    });
  }

  search(): void {
    const searchParameters = JSON.parse(JSON.stringify(this.backendService.getSearchParameters()));
    this.router.navigate(['/search'], { queryParams: searchParameters });
  }

  private setSession(authenticatedUser) {
    localStorage.setItem('bearerToken', authenticatedUser.bearerToken);
    localStorage.setItem("expiresAt", authenticatedUser.expiresAt);
    localStorage.setItem("userId", authenticatedUser.userId);
    localStorage.setItem("email", authenticatedUser.email);
    localStorage.setItem("firstName", authenticatedUser.firstName);
    localStorage.setItem("lastName", authenticatedUser.lastName);
    localStorage.setItem("profilePicture", authenticatedUser.profilePicture);
    localStorage.setItem("status", authenticatedUser.status);
    localStorage.setItem("previews", authenticatedUser.previews);
    this.userInfoSource.next(authenticatedUser);
  }
  
  loadUserInfoFromLocalStorage(): void {
    if(Math.floor(Date.now() / 1000) < parseInt(localStorage.getItem("expiresAt"))) {
      this.userInfoSource.next({
        bearerToken: localStorage.getItem("bearerToken"),
        expiresAt: parseInt(localStorage.getItem("expiresAt")),
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        profilePicture: localStorage.getItem("profilePicture"),
        status: localStorage.getItem("status"),
        previews: localStorage.getItem("previews")
      });
    } else {
      this.logout();
    }
  }
  
  logout() {
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("profilePicture");
    localStorage.removeItem("status");
    localStorage.removeItem("previews");
    this.userInfoSource.next(null);
  }

}
